import Vue3Toastify, { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Vue3Toastify, {
    autoClose: 8000,
    position: toast.POSITION.TOP_CENTER,
    icon: false,
    pauseOnHover: true,
    transition: "slide",
  });

  return {
    provide: {
      toast,
    },
  };
});
